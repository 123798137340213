import { useStaticQuery, graphql } from "gatsby";

export const GetFaqs = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          faqs(first: 6) {
            edges {
              node {
                faqTitle
                faqDescription
              }
            }
          }
        }
      }
    `
  );
  return data.wpgraphql.faqs.edges;
};
