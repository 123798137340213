import React from "react";
import handleAccordion from "common/handleAccordion";
import { GetFaqs } from "components/ApiServices/GetFaqs";

const Faq = () => {
  const faqs = GetFaqs();
  return (
    <>
      {faqs && faqs.length > 0 && (
        <section className="app-faq section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content md-mb50">
                  <h2 className="mb-30 text-center">
                    Frequently Asked Questions
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion" id="accordionColumn1">
                  {faqs.slice(0, 3).map((faq, i) => {
                    return (
                      <div className="card">
                        <div className="card-header" id={`heading${i}`}>
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${i}`}
                              aria-expanded={i === 0}
                              aria-controls={`collapse${i}`}
                            >
                              <div className="title">
                                <h6 className="faqt">{faq?.node?.faqTitle}</h6>
                              </div>
                            </button>
                          </h2>
                        </div>

                        <div
                          id={`collapse${i}`}
                          className={`collapse`}
                          aria-labelledby={`heading${i}`}
                          data-parent="#accordionColumn1"
                        >
                          <div className="card-body">
                            {faq?.node?.faqDescription}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="accordion" id="accordionColumn2">
                  {faqs.slice(3, 6).map((faq, i) => {
                    return (
                      <div className="card" key={i + 3}>
                        <div className="card-header" id={`heading${i + 3}`}>
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${i + 3}`}
                              aria-expanded={i === 0}
                              aria-controls={`collapse${i + 3}`}
                            >
                              <div className="title">
                                <h6 className="faqt">{faq?.node?.faqTitle}</h6>
                              </div>
                            </button>
                          </h2>
                        </div>

                        <div
                          id={`collapse${i + 3}`}
                          className={`collapse`}
                          aria-labelledby={`heading${i + 3}`}
                          data-parent="#accordionColumn2"
                        >
                          <div className="card-body">
                            {faq?.node?.faqDescription}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Faq;
