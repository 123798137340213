import React from "react";

const girlSvg = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 259.25 292.76"  >
<g>
	<ellipse className="st4" cx="183.04" cy="284.11" rx="76.21" ry="8.66"/>
	<g>
		<g>
			<g>
				<rect x="140.67" y="102.78" className="st3" width="75.89" height="5.11"/>
				<g>
					<polygon className="st1" points="216.56,102.79 152.46,102.79 176.74,43.6 240.83,43.6 					"/>
					<path className="st20" d="M216.92,103.33h-65.27l24.72-60.27h65.27L216.92,103.33z M153.28,102.24h62.91l23.83-58.09H177.1
						L153.28,102.24z"/>
				</g>
				<polygon className="st6" points="211.02,97.81 160.65,97.81 179.73,51.3 230.1,51.3 				"/>
			</g>
			<g>
				<rect x="189.95" y="109.53" className="st3" width="1.09" height="172.36"/>
				<rect x="129.28" y="107.9" className="st21" width="122.43" height="9.74"/>
			</g>
			<g>
				<rect x="29.39" y="181.93" className="st3" width="1.09" height="99.02"/>
				<path className="st0" d="M59.88,186.56H0v0c1.27-6.08,6.63-10.43,12.84-10.43h34.2C53.25,176.13,58.61,180.48,59.88,186.56
					L59.88,186.56z"/>
				<path className="st0" d="M45.48,281.59H14.4v-0.01c0-3.77,3.06-6.83,6.83-6.83h17.42C42.42,274.75,45.48,277.81,45.48,281.59
					L45.48,281.59z"/>
			</g>
			<g>
				<g>
					<path className="st9" d="M76.14,33.61c1.53,2.2,3.73,5.32,3.97,8.04c0.35,3.96-5.99,1.18-6.06,1.53
						C74.05,43.18,76.14,33.61,76.14,33.61z"/>
					<g>
						<rect x="46.42" y="38.28" className="st5" width="20.04" height="29.37"/>
						<path className="st9" d="M66.46,43.31v20.97c-6.31-3.97-11.77-9.86-16.49-16.66L66.46,43.31z"/>
					</g>
					<path className="st5" d="M77.72,35.4c-0.62,10.15-4.53,21.62-15.18,18.79c-8.37-2.22-17.58-9.27-17.58-19.43
						c0-10.16,7.03-18.4,15.71-18.4c5.42,0,12.25,2.63,15.47,7.72C78.07,27.12,77.99,30.97,77.72,35.4z"/>
					<path className="st11" d="M59.98,32.45c2.84-7.52,9.33-8.26,9.33-8.26s15.04,14.87,14.1-0.49c-0.74-12.21-12.67-11.06-12.67-11.06
						c-5.83-7.59-15.18-4.62-18.59-0.22s-2.2,1.87-10.23,4.73c-8.03,2.86-3.74,15.07-5.17,15.95c-10.67,0-8.69,10.56-8.69,10.56
						l25.26,8.24l6.09-16.61L59.98,32.45z"/>
					<g>
						<path className="st5" d="M62.62,33.32c1.56,2.71,1.14,5.87-0.94,7.08c-2.08,1.2-5.04-0.02-6.6-2.72
							c-1.56-2.71-1.14-5.87,0.94-7.08C58.1,29.39,61.06,30.61,62.62,33.32z"/>
						<path className="st9" d="M59.39,36.57c-0.36-0.69-0.65-1.35-1.13-1.82c-0.23-0.24-0.51-0.43-0.82-0.59
							c-0.31-0.17-0.65-0.31-1.01-0.47l0.05-0.28c0.4-0.02,0.81,0.03,1.21,0.16c0.4,0.13,0.78,0.36,1.09,0.68
							c0.3,0.31,0.52,0.69,0.66,1.08c0.14,0.39,0.21,0.79,0.23,1.19L59.39,36.57z"/>
					</g>
					
						<ellipse transform="matrix(0.9989 -0.0473 0.0473 0.9989 -0.3797 2.3542)" className="st11" cx="49.52" cy="9.2" rx="8.49" ry="9.2"/>
					<circle className="st9" cx="69.01" cy="41.15" r="3.82"/>
				</g>
				<g>
					<path className="st0" d="M84.01,139.88l-64.18-8.18l12.1-63.79c0.85-4.46,3.82-8.25,7.95-10.12c3.45-1.57,7.39-1.67,10.92-0.28
						l21.87,9.72c4.21,1.87,7.11,5.83,7.64,10.4l1.23,20.84l1.41,23.62L84.01,139.88z"/>
					<path className="st5" d="M142.74,94.06l16.99-4.99c1.22-0.36,2.54-0.16,3.6,0.54c4.65,3.09,17.03,11.82,13.9,15.39
						c-1.56,1.78-15.76-2.4-15.76-2.4l-20.57,5.74L142.74,94.06z"/>
					<path className="st22" d="M82.95,122.07c-5.35-6.81-8.52-15.8-9.58-26.92l8.17,3.31L82.95,122.07z"/>
					<path className="st0" d="M70.73,66.42l2.56,1.1c5.26,2.27,9.98,5.64,13.84,9.88l21.06,23.2l34.55-6.55l4.55,18.55l-43.41,13.57
						c-3.43,0.61-6.93-0.57-9.29-3.14L63.28,88.97L70.73,66.42z"/>
					<path className="st22" d="M107.79,100.97c-0.1-0.11-10.38-11.3-19.28-21.28c-8.8-9.86-22.21-14.66-22.35-14.71l0.36-1.03
						c0.14,0.05,13.81,4.93,22.8,15.01c8.89,9.97,19.16,21.16,19.27,21.27L107.79,100.97z"/>
					
						<rect x="79.48" y="87.07" transform="matrix(0.7182 -0.6958 0.6958 0.7182 -52.5986 86.1108)" className="st22" width="1.09" height="41.86"/>
				</g>
				<g>
					<g>
						<g>
							<polygon className="st3" points="81.68,236.36 67.84,263.33 102.81,281.28 93.5,260 101.1,243.13 							"/>
							
								<rect x="83.36" y="260.91" transform="matrix(0.9593 -0.2824 0.2824 0.9593 -70.2286 35.6419)" className="st23" width="10.36" height="1.09"/>
						</g>
						<path className="st24" d="M103.46,254.08l-31.69-11.82l21.7-67.52c3.73-10,15.22-14.94,25.66-11.05h0
							c10.44,3.9,15.89,15.16,12.16,25.16L103.46,254.08z"/>
					</g>
					<g>
						<g>
							<polygon className="st3" points="134.19,249.52 137.28,281.61 176.6,281.61 158.6,266.92 154.37,248.43 							"/>
							
								<rect x="148.62" y="268.95" transform="matrix(0.8757 -0.4828 0.4828 0.8757 -110.9788 107.808)" className="st23" width="10.64" height="1.09"/>
						</g>
						<path className="st20" d="M162.55,253.33l-34.36,5.45l-23.53-69.2l-4.29-12.62l-58.44,0.47c-14.45,0.33-25.95-12.05-24.54-26.46
							l2.43-19.29l57.82,7.28l38,4.18c15.82,2.73,20.67,12.31,23.97,24.61L162.55,253.33z"/>
						<path className="st16" d="M104.66,189.59l-4.29-12.62l7.91-0.27C109.01,181.45,107.12,186.77,104.66,189.59z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
</svg>

);

export default girlSvg;