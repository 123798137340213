import React from "react";
import { GetHomeServiceData } from "components/ApiServices/GetHomeServiceData";
import { Link } from "gatsby";

const Services5 = () => {
  const ServiceData = GetHomeServiceData();

  return (
    <section className="services box lficon section-padding position-re">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Best Features
              </h6>
              <h3 className="wow color-font">
                We are a new digital product development agency
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          {ServiceData &&
            ServiceData?.map((item) =>
              item.node.technologiesData.edges.slice(0, 2).map((val, index) => (
                <div
                  className="col-lg-4 wow fadeInLeft"
                  data-wow-delay={
                    index == 0
                      ? ".5s"
                      : index == 1
                      ? ".7s"
                      : index === 2
                      ? ".9s"
                      : ".5s"
                  }
                  key={index}
                >
                  <div className="item-box no-curve">
                    <Link
                      to={`/services/${val.node.urlSlug.nodes[0].slug}/${val.node.slug}`}
                    >
                      <div>
                        <span
                          className={`icon color-font ${val.node.icon}`}
                        ></span>
                      </div>
                      <div className="cont">
                        <h6>{val.node.title}</h6>
                        <p>
                          {val.node.titleSlogan.length > 200
                            ? val.node.titleSlogan.substring(0, 200) + ".."
                            : val.node.titleSlogan}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
        </div>
      </div>
    </section>
  );
};

export default Services5;
