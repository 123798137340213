import React, { useLayoutEffect, useState } from "react";

const WaveSvg = () => {
  
  const [viewBox, setViewBox] = useState("0 0 1200 250");

  useLayoutEffect(() => {
    const handleResize = () => {
      const viewBoxValue = window.innerWidth < 550 
                            ? 
                            "0 0 900 500" 
                            : 
                            window.innerWidth < 768 && window.innerWidth > 550
                              ?
                              "0 0 950 400" 
                              :
                              "0 0 1200 250";

      setViewBox(viewBoxValue);
    };
  
    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
  <svg className="wave-top" viewBox={viewBox}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 108.306L50 114.323C100 120.34 200 132.374 300 168.476C400 204.578 500 264.749 600 246.698C700 228.647 800 132.374 900 108.306C1000 84.2382 1100 132.374 1150 156.442L1200 180.51V-8.5451e-06H1150C1100 -8.5451e-06 1000 -8.5451e-06 900 -8.5451e-06C800 -8.5451e-06 700 -8.5451e-06 600 -8.5451e-06C500 -8.5451e-06 400 -8.5451e-06 300 -8.5451e-06C200 -8.5451e-06 100 -8.5451e-06 50 -8.5451e-06H0V108.306Z"
      fill="#18191d"
    />
  </svg>
  )
};

export default WaveSvg;
