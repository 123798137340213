import React, { useState } from "react";
import { Link } from "gatsby";
import initIsotope from "common/initIsotope";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const techList = [
  {
    name: "Magento",
    dataFilter: ".magento",
  },
  {
    name: "Shopify",
    dataFilter: ".shopify",
  },
  {
    name: "Wordpress",
    dataFilter: ".wordpress",
  },
  {
    name: "SAAS",
    dataFilter: ".saas",
  },
  {
    name: "Branding",
    dataFilter: ".branding",
  },
  {
    name: "Mobile Apps",
    dataFilter: ".mobile",
  },
  {
    name: "REST/SOAP",
    dataFilter: ".restsoap",
  },
  {
    name: "E-Learning",
    dataFilter: ".elearning",
  },
  {
    name: "Other Commerce",
    dataFilter: ".ecommerce",
  },
];

const WorksStyle2 = ({
  grid,
  hideTitle,
  hideFilter,
  filterPosition,
  projectData,
  propData,
}) => {
  const parts = propData?.path.split("/");
  const lastPart = parts && parts[parts?.length - 2];

  React.useEffect(() => {
    setTimeout(() => {
      initIsotope();
    }, 1000);
  }, []);

  const projectType = (arr) => {
    var str = "";
    if (arr.length > 0) {
      arr.forEach((val) => {
        str += " " + val;
      });
    }
    return str;
  };

  return (
    <section
      className={`${
        grid ? (grid === 3 ? "three-column" : null) : null
      } portfolio section-padding pb-70`}
    >
      {!hideTitle && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head text-center">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  Portfolio
                </h6>
                <h3 className="wow color-font">
                  Our Recent Web Design &amp; <br /> Some Past Projects.
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={"container"}>
        <div className="row">
          {!hideFilter && (
            <div
              className={`filtering ${
                filterPosition === "center"
                  ? "text-center"
                  : filterPosition === "left"
                  ? "text-left"
                  : "text-right"
              } col-12`}
            >
              <div className="filter">
                <span data-filter=".all" className="active">
                  All
                </span>
                {techList &&
                  techList.map((item, i) => (
                    <span key={i} data-filter={item.dataFilter}>
                      {item.name}
                    </span>
                  ))}
              </div>
            </div>
          )}

          <div className="gallery full-width">
            {projectData &&
              projectData
                .slice(0, lastPart === "work" ? projectData.length : 12)
                .map((data, i) => (
                  <div
                    key={i}
                    className={`${
                      grid === 3
                        ? "col-lg-3 col-md-6"
                        : grid === 2
                        ? "col-md-6"
                        : "col-12"
                    } ${projectType(
                      data.node.projectType
                    )} items wow fadeInUp work-section-height`}
                    data-wow-delay=".1s"
                  >
                    <div className="item-img" style={{ height: 160 }}>
                      {/* {console.log(data.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData, "data")} */}
                      <Link
                        to={`/work/${data.node.slug}`}
                        className="imago wow"
                      >
                        <GatsbyImage
                          image={data.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                          alt={data.node.imageAlt}
                        />
                        <div className="item-img-overlay"></div>
                      </Link>
                    </div>
                    <div className="cont">
                      <Link
                        to={`/work/${data.node.slug}`}
                        className="imago wow"
                      >
                        <h6>{data.node.projectName}</h6>
                      </Link>
                      <p>
                        <small>
                          <i>{data.node.shortDescription}</i>
                        </small>
                      </p>

                      <span>
                        {data.node &&
                          data.node.technologiesData &&
                          data.node.technologiesData.nodes
                            .slice(0, 3)
                            .map((tech, index) => (
                              <Link
                                key={index}
                                to={`/technologies/${tech.title.toLowerCase()}`}
                              >
                                <p style={{ margin: 0 }}>
                                  {(index ? ", " : "") + tech.title}
                                </p>
                              </Link>
                            ))}
                      </span>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorksStyle2;
