import { useStaticQuery, graphql } from "gatsby";

export const GetHomeServiceData = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        services {
          edges {
            node {
              technologiesData(last: 2) {
                edges {
                  node {
                    title
                    slug
                    titleSlogan
                    icon
                    urlSlug {
                      nodes {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpgraphql.services.edges;
};
