import React from "react";
import introData from "data/sections/intro.json";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay, Navigation, Pagination, Parallax } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import removeSlashFromPagination from "common/removeSlashpagination";
import fadeWhenScroll from "common/fadeWhenScroll";
import GirlSvg from "../Svg/girlSvg";
import WaveSvg from "../Svg/waveSvg";

SwiperCore.use([Autoplay, Navigation, Pagination, Parallax]);

const IntroWithSlider = ({ sliderRef }) => {
  const [load, setLoad] = React.useState(true);
  React.useEffect(() => {
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .caption"));
    setTimeout(() => {
      removeSlashFromPagination();
      setLoad(false);
    }, 1000);
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  return (
    <header
      ref={sliderRef}
      className="slider slider-prlx fixed-slider text-center"
    >
      <div className="swiper-container parallax-slider">
        {!load ? (
          <Swiper
            speed={1000}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            loop={true}
            // parallax={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            pagination={{
              type: "fraction",
              clickable: true,
              el: paginationRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.params.pagination.el = paginationRef.current;
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].childNodes[0].setAttribute(
                    "data-swiper-parallax",
                    0.75 * swiper.width
                  );
                }

                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;

                swiper.params.pagination.el = paginationRef.current;

                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();

                swiper.pagination.destroy();
                swiper.pagination.init();
                swiper.pagination.update();
              });
            }}
            className="swiper-wrapper"
            slidesPerView={1}
          >
            {introData.map((slide) => (
              <SwiperSlide key={slide.id} className="swiper-slide swiperslider">
                <span className="background-svg">
                  <WaveSvg />
                </span>
                <div
                  className="bg-img valign intro-sec"
                  // style={{ backgroundImage: `url(${slide.image})` }}
                  // data-overlay-dark="6"
                >
                  <img loading ="lazy"
                    src={slide.slide1code}
                    alt="code"
                    width="50"
                    className="slide-code01 animated-element"
                  />
                  <img loading ="lazy"
                    src={slide.slide1js}
                    alt="js"
                    width="50"
                    className="slide-js animated-element"
                  />
                  {/* <img src={slide.mainObj} alt="" width="50" className="slide-mainobj" /> */}
                  <span className="slide-mainobj animated-element">
                    <GirlSvg />
                  </span>

                  {/* html svg */}
                  <img loading ="lazy"
                    src={slide.slideRight1}
                    alt="html"
                    width="50"
                    className="slide-right1 animated-element"
                  />
                  <img loading ="lazy"
                    src={slide.slideRight2}
                    alt="css"
                    width="50"
                    className="slide-right2 animated-element"
                  />
                  <img loading ="lazy"
                    src={slide.slideRight3}
                    alt="man"
                    width="50"
                    className="slide-right3 animated-element"
                  />

                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-10">
                        <div className="caption center mt-30">
                          <h1 className="animate-charcter">{slide.title}</h1>
                          {slide?.content && <p>{slide.content}</p>}
                          <a
                            href="#contact"
                            className="butn bord curve mt-30 banner-cta"
                          >
                            Get free consultation
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
        <div className="setone setwo">
          <div
            ref={navigationNextRef}
            className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer"
          >
            <i className="fas fa-chevron-right"></i>
          </div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer"
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div
          ref={paginationRef}
          className="swiper-pagination top botm text-dark"
        ></div>

        <div className="social-icon">
          <a
            className="text-dark"
            href="http://g.page/evolvan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-map-marker"></i>
          </a>
          <a className="text-dark" href="tel:+918360801493">
            <i className="fa fa-mobile"></i>
          </a>
          <a className="text-dark" href="mailto:info@evolvan.com">
            <i className="fa fa-envelope"></i>
          </a>
          <a
            className="text-dark"
            href="http://fb.com/evolvan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            className="text-dark"
            href="http://twitter.com/evolvan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            className="text-dark"
            href="http://linkedin.com/company/evolvan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </header>
  );
};

export default IntroWithSlider;
